<template>
  <TransitionRoot
      appear
      :show="isOpen"
      as="template"
  >
      <Dialog
          as="div"
          class="relative z-40"
          :initialFocus="initialFocus"
          @close="closeModal"
      >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
              <div
                  class="fixed inset-0 bg-opacity-25 bg-black"
              />
          </TransitionChild>

          <div
              class="fixed inset-0 overflow-y-auto"
          >
              <div
                  class="flex w-auto min-h-full md:p-4 text-right"
                  :class="position"
              >
                  <TransitionChild
                      as="template"
                      enter="duration-300 ease-out"
                      enter-to="opacity-100 scale-100"
                      leave="duration-200 ease-in"
                      leave-from="opacity-100 scale-100"
                      :enter-from="transitionEnterFrom"
                      :leave-to="transitionLeaveTo"
                  >
                      <DialogPanel
                          class="relative transform rounded-t-2xl md:rounded-lg bg-white dark:bg-cool-900 text-left align-middle transition-all w-full md:max-w-sm py-6 px-4 md:px-5 scroll-shadows"
                          :class="isTransparent"
                      >
                          <!-- This is a focus hack so no input takes initial focus if it's within the slot -->
                          <a
                              ref="initialFocus"
                              class="hidden h-0 max-h-0"
                          >
                          </a>
                         
                          <slot/>
                      </DialogPanel>
                  </TransitionChild>
              </div>
          </div>
      </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue';

interface ModalDialogProps {
  isOpen: boolean;
  disableEscapeClose: boolean;
  type: 'modal' | 'drawer' | 'side';
  side: 'left' | 'right';
  transparent: boolean;
  open: Function;
  close: Function;
}

const props = defineProps({
  isOpen: { type: Boolean },
  disableEscapeClose: { type: Boolean },
  type: null,
  side: null,
  transparent: { type: Boolean },
  open: { type: Function },
  close: { type: Function }
});

const position = computed(() => {
  if (props.type === 'drawer') {
    return 'items-end justify-center ';
  }
  if (props.type === 'side') {
    return props.side === 'right' ? 'items-stretch justify-end' : 'items-stretch justify-start';
  }
  if (props.type === 'modal') {
    return 'items-end md:items-center justify-center ';
  }
});

const transitionEnterFrom = computed(() => {
  return props.side === 'right' ? 'opacity-0 scale-95 translate-x-full' : 'opacity-0 scale-95 -translate-x-full';
});

const transitionLeaveTo = computed(() => {
  return props.side === 'right' ? 'opacity-0 scale-95 translate-x-full' : 'opacity-0 scale-95 -translate-x-full';
});

const isTransparent = computed(() => {
  return props.transparent ? 'bg-opacity-0' : 'shadow-xl';
});

const emit = defineEmits(['update:modelValue']);
const closeModal = () => {
  emit('update:isOpen', false);
};

const handleKey = (event) => {
  if (event.key === 'Escape' && open) {
      event.preventDefault();
  }
};
      
watch(() => props.isOpen, (value) => {
  if (value && props.disableEscapeClose) {
      addEventListener('keydown', handleKey, false);
  } else {
      removeEventListener('keydown', handleKey, false);
  }
}, { immediate: true });

const initialFocus = ref(null);
</script>
