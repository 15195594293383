<script setup lang="ts">
const route = useRoute();
const appConfig = useAppConfig();
const { isHelpSlideoverOpen } = useDashboard();
const app = useAppStore();

const links = [
    {
        id: 'home',
        label: 'Home',
        icon: 'i-heroicons-home',
        to: `/app/${app.organisation.id}`,
        tooltip: {
            text: 'Home',
            shortcuts: ['G', 'H'],
        },
    },
    {
        id: 'events',
        label: 'Events',
        icon: 'i-heroicons-calendar',
        to: '/users',
        tooltip: {
            text: 'Events',
        },
    },
    {
        id: 'members',
        label: 'Members',
        icon: 'i-heroicons-user-group',
        to: '/app/members',
        tooltip: {
            text: 'Members',
            shortcuts: ['G', 'U'],
        },
    },
    {
        id: 'teams',
        label: 'Teams',
        icon: 'i-heroicons-users',
        to: '/users',
        tooltip: {
            text: 'Teams',
        },
    },
    {
        id: 'settings',
        label: 'Settings',
        to: '/app/settings',
        icon: 'i-heroicons-cog-8-tooth',
        children: [
            {
                label: 'General',
                to: '/app/settings',
                exact: true,
            },
            {
                label: 'Members',
                to: '/app/settings/members',
            },
            {
                label: 'Notifications',
                to: '/app/settings/notifications',
            },
        ],
        tooltip: {
            text: 'Settings',
            shortcuts: ['G', 'S'],
        },
    },
];

const footerLinks = [
    {
        label: 'Invite people',
        icon: 'i-heroicons-plus',
        to: '/settings/members',
    },
    {
        label: 'Help & Support',
        icon: 'i-heroicons-question-mark-circle',
        click: () => (isHelpSlideoverOpen.value = true),
    },
];

const groups = [
    {
        key: 'links',
        label: 'Go to',
        commands: links.map((link) => ({ ...link, shortcuts: link.tooltip?.shortcuts })),
    },
];
</script>

<template>
    <UDashboardLayout>
        <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
            <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
                <template #left>
                    <div class="flex items-center w-full justify-between">
                        <OrganisationDropdown />
                        <UserDropdown />
                    </div>
                </template>
            </UDashboardNavbar>

            <UDashboardSidebar>
                <template #header>
                    <UDashboardSearchButton />
                </template>

                <UDashboardSidebarLinks :links="links" />

                <UDivider />

                <div class="flex-1" />

                <UDashboardSidebarLinks :links="footerLinks" />

                <UDivider class="sticky bottom-0" />
            </UDashboardSidebar>
        </UDashboardPanel>

        <slot />

        <!-- ~/components/HelpSlideover.vue -->
        <HelpSlideover />
        <!-- ~/components/NotificationsSlideover.vue -->
        <NotificationsSlideover />

        <ClientOnly>
            <LazyUDashboardSearch :groups="groups" />
            <ModalContainer />
        </ClientOnly>
    </UDashboardLayout>
</template>
