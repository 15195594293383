<script setup lang="ts">
import {
  isSignInDialogOpen,
  isSignUpDialogOpen,
  createOrganisationDialog,
} from "~/composables/dialog";
const app = useAppStore();

const onOrganisationCreateSuccess = async () => {
  app.organisationListUserQuery.refetch();
  app.init();
};
</script>

<template>
  <template v-if="isHydrated">
    <ModalDialog v-model="isSignInDialogOpen" class="container rounded-2xl max-w-md h-auto">
      <UserSignIn />
    </ModalDialog>
    <ModalDialog v-model="isSignUpDialogOpen" class="w-full h-full">
      <UserSignUp />
    </ModalDialog>
    <ModalDialog 
      v-model:isOpen="createOrganisationDialog.isOpen" 
      v-bind="createOrganisationDialog"
      class="w-full h-full"
      >
        <OrganisationCreate @success="onOrganisationCreateSuccess"/>
    </ModalDialog>
  </template>
</template>
