<script setup lang="ts">
import { createOrganisationDialog } from "~/composables/dialog";

const api = useApi();
const user = useUser();
const app = useAppStore();

onServerPrefetch(async () => {
  await app.organisationListUserQuery.suspense();
});

const items = computed(() => [
  [
    ...app.organisationListUser?.length ? app.organisationListUser : [
      {
        label: 'No organisations',
        disabled: true,
      },
    ]
  ],
  [
    {
        label: 'Create organisation',
        icon: 'i-heroicons-plus',
        click: () => {
          createOrganisationDialog.open({ type: 'side', side: 'right' })
        },
      }
  ]
]);
</script>

<template>
  <UDropdown :items="items" :ui="{ width: 'w-52', item: { disabled: 'cursor-text select-text' } }" :popper="{ placement: 'bottom-start' }">
    <UButton color="gray" variant="ghost" class="w-full">
      <!-- <UAvatar :src="organisation..src" size="2xs" /> -->
      <span class="truncate max-w-[125px] text-xs flex gap-2 items-center text-gray-900 dark:text-white font-semibold">
        <UAvatar size="2xs" :ui="{ rounded: 'rounded-md' }" :src="app.organisation?.image" alt="Avatar" />
        <span class="truncate">{{ app.organisation?.name }}</span>
      </span>
    </UButton>

    <template #organisation="{ item }">
      <div class="w-full justify-between flex gap-1">
        <span class="truncate max-w-[125px]">{{ item.label }}</span>
        <UBadge size="xs" color="gray">{{item.type}}</UBadge>
      </div>
    </template>

    <template #item="{ item }">
      <span class="truncate">{{ item.label }}</span>

      <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto" />
    </template>
  </UDropdown>
</template>